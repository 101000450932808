$header-background-color: #44d090;
$header-logo-width: 120px;
$header-logo-padding-top: 0;
$header-nav-link-color: #ffffff;
$header-nav-link-hover-color: #35363a;
$header-user-color: #ffffff;
$header-user-negative-balance-color: #ff4444;
$header-user-hover-color: #35363a;

.main-header {
  display: flex;
  align-items: center;
  background-color: $header-background-color;
  height: 58px;
  padding: 0 24px;

  .logo {
    width: $header-logo-width;
    padding-top: $header-logo-padding-top;
  }

  .nav {
    display: flex;
    align-items: stretch;
    margin-left: 10px;
    margin-right: 10px;

    .nav-item {
      padding: 18px 20px;
      color: $header-nav-link-color;

      &:hover,
      &:active {
        color: $header-nav-link-hover-color;
      }
    }
  }

  .search {
    display: flex;
    align-items: center;
  }

  .user-wrapper {
    margin-left: auto;
  }

  .user-block {
    display: flex;
    height: 54px;
    align-items: center;
    color: $header-user-color;
    cursor: pointer;

    &:hover,
    &:active {
      color: $header-user-hover-color;

      .negative-balance {
        color: $header-user-hover-color;
      }
    }

    .user-icon {
      margin-left: 12px;
      font-size: 24px;
    }

    .user-info {
      text-align: right;
    }

    .negative-balance {
      color: $header-user-negative-balance-color;
    }

    .down-icon {
      margin-left: 8px;
    }
  }
}
